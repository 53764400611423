.editor {
  width: 100%;
  min-height: 5em;
  margin: 0;
  padding: 0px 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.editor-menu {
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 10px;
  text-align: right;
}

.editor-menu Button {
  padding: 0.25em;
  margin: 0;
}

.editor-textarea {
  height: auto;
  min-height: 80px;
  padding-top: 0;
  padding-bottom: 8px;
  margin-top: 0;
}

.ProseMirror:focus,
.editor textarea:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.editor-textarea:focus {
  resize: vertical;
}

.ProseMirror[required]:invalid,
.editor-textarea[required]:invalid {
  border-color: #dc3545;
}

.ProseMirror[type="checkbox"] {
  margin-top: 0.375rem;
  margin-right: 0.25rem;
}

.editor .MuiFormControl-root {
  margin-top: 8px;
  margin-bottom: 8px;
}

.editor .MuiInputLabel-outlined.Mui-focused {
  color: #3f51b5;
}

.editor .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3f51b5;
}
