.welcome {
  align-items: center;
  justify-content: center;
  text-align: center;
}

h1 {
  font-size: 36px;
  /* color: #333; */
}

p {
  font-size: 18px;
  /* color: #555; */
}

.input-container {
  margin-bottom: 40px;
}

h2 {
  font-size: 24px;
  /* color: #333; */
  margin-bottom: 10px;
}

.ad-lib {
  font-size: 24px;
  /* color: #333; */
  margin-bottom: 20px;
  position: relative;
}

#ad-lib-input {
  display: inline;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #448c74;
  width: 200px;
  outline: none;
}

.blinking-cursor {
  content: "";
  display: inline-block;
  top: 50%;
  right: 0;
  height: 24px;
  width: 2px;
  background-color: #448c74;
  opacity: 0;
  animation: blink 2s step-end infinite;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

button {
  display: inline-block;
  width: auto;
  background-color: #448c74;
  color: #fff;
  text-decoration: none;
  padding: 15px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
}

button:hover {
  background-color: #2c6651;
}

@media screen and (max-width: 480px) {
  header {
    padding: 10px;
  }

  h1 {
    font-size: 28px;
  }

  p,
  .ad-lib {
    font-size: 16px;
  }

  h2 {
    font-size: 20px;
  }
}
